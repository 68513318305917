import blink from './blink.png';
import laptop from './laptop.png';

const HeroSection = () => {
   return (
      <div className="py-28 px-8 sm:py-40 sm:px-12 bg-primary">
         <div className="container flex justify-around">
            <div className="flex flex-col justify-center mr-4">
               <p className="text-2xl sm:text-6xl font-['Casanova']">Hey, my</p>
               <p className="text-2xl sm:text-6xl font-['Casanova'] mt-2 leading-10">name is <span className="font-primary">Thim</span></p>
               <p className="font-secondary sm:text-lg mt-4">I'm a software engineer from<br/> Kuala Lumpur, Malaysia.</p>
            </div>
            <div>
               <img src={blink} onMouseOver={MouseOver} onMouseLeave={MouseLeave} alt="Thim" className="hero-featured-image" />
            </div>
         </div>
      </div>
   );
}

function MouseOver(event: any) {
   event.target.src = laptop;
}

function MouseLeave(event: any) {
   event.target.src = blink;
}

export default HeroSection;
