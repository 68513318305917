const WorkSection = () => {
   return (
      <div className="bg-tertiary px-8 py-24">
         <div className="container flex justify-around">
            <div className="flex flex-col justify-center">
               <p className="text-2xl lg:text-4xl font-secondary text-center">Wish to work with me?</p>
               <a href="mailto:thimyunlee@gmail.com" className="bg-[#244aff] hover:bg-[#244affde] text-center text-white font-bold py-2 px-4 rounded-full w-auto mt-4">
                  Drop me an email
               </a>
            </div>
         </div>
      </div>
   );
}

export default WorkSection;
