import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import sbill1 from "./assets/sbill/1.jpg";
import sbill2 from "./assets/sbill/2.jpg";
import sbill3 from "./assets/sbill/3.jpg";
import sbill4 from "./assets/sbill/4.jpg";
import sbill5 from "./assets/sbill/5.jpg";
import fitfit1 from "./assets/fitfit/1.jpeg";
import fitfit2 from "./assets/fitfit/2.jpeg";
import fitfit3 from "./assets/fitfit/3.jpeg";
import fitfit4 from "./assets/fitfit/4.jpeg";
import fitfit5 from "./assets/fitfit/5.jpeg";

const ProjectSection = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
              }
            }
        ]
    };
    
   return (
      <div className="bg-white py-28 px-8 sm:py-40 sm:px-32">
        <div className="container">
            <div className="flex flex-col justify-center">
               <p className="text-2xl sm:text-6xl font-primary font-bold">Notable Projects</p>
               <p className="text-xl sm:text-2xl font-secondary font-light italic mt-2">partial of my completed projects <br/>that are worth mentioning</p>
            </div>
        </div>
        <hr className="my-12" />
        <div className="container flex flex-col lg:flex-row justify-around">
            <div className="flex flex-col justify-center lg:w-2/5 pr-12 mb-12">
               <p className="text-xl sm:text-2xl font-primary font-bold">Sbill</p>
               <p className="font-body mt-2">a bill splitting application tailoring for Malaysians. Founded by myself. Created the product from scratch within 1 year time.</p>
               <table className="table-auto font-body mt-12">
                    <tbody>
                        <tr className="border-b">
                            <td className="py-4 w-24">Deliverables</td>
                            <td className="px-4 py-4">Product, Design, Web Development, Mobile App Development</td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-4 w-24">Platforms</td>
                            <td className="px-4 py-4">iOS, Android, Web</td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-4 w-24">Tech stack</td>
                            <td className="px-4 py-4">Flutter, Vue</td>
                        </tr>
                        <tr>
                            <td className="py-4 w-24">Created with</td>
                            <td className="px-4 py-4">Myself</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div data-aos="fade-up" className="rounded-xl bg-[#2c61c721] lg:w-3/5">
                <Slider {...settings}>
                    <div className="p-8">
                        <img src={sbill1} alt="Sbill" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={sbill2} alt="Sbill" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={sbill3} alt="Sbill" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={sbill4} alt="Sbill" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={sbill5} alt="Sbill" className="rounded-xl" />
                    </div>
                </Slider>
            </div>
        </div>
        <hr className="my-24" />
        <div className="container flex flex-col lg:flex-row justify-around">
            <div className="flex flex-col justify-center lg:w-2/5 pr-12 mb-12">
               <p className="text-xl sm:text-2xl font-primary font-bold">FitFit</p>
               <p className="font-body mt-2">a digital fitness app - Your Digital Personal Trainer. FitFit is a free fitness coach app with premium subscription options.</p>
               <table className="table-auto font-body mt-12">
                    <tbody>
                        <tr className="border-b">
                            <td className="py-4 w-24">Deliverables</td>
                            <td className="px-4 py-4">Product, Mobile App Development</td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-4 w-24">Platforms</td>
                            <td className="px-4 py-4">iOS, Android</td>
                        </tr>
                        <tr className="border-b">
                            <td className="py-4 w-24">Tech stack</td>
                            <td className="px-4 py-4">Flutter</td>
                        </tr>
                        <tr>
                            <td className="py-4 w-24">Created with</td>
                            <td className="px-4 py-4">FitFit Global</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div data-aos="fade-up" className="rounded-xl bg-[#7158fc14] lg:w-3/5">
                <Slider {...settings}>
                    <div className="p-8">
                        <img src={fitfit1} alt="FitFit" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={fitfit2} alt="FitFit" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={fitfit3} alt="FitFit" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={fitfit4} alt="FitFit" className="rounded-xl" />
                    </div>
                    <div className="p-8">
                        <img src={fitfit5} alt="FitFit" className="rounded-xl" />
                    </div>
                </Slider>
            </div>
        </div>
      </div>
   );
}

export default ProjectSection;
