import React from 'react';
import './App.css';
import { Card } from './Button';
import { Clock } from './Clock';
import { Notice } from './Notice';
import HelloWorld from './HelloWorld';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ProjectSection from './ProjectSection';
import WorkSection from './WorkSection';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      {/* <header>
        <HelloWorld>
          ehehhehe
        </HelloWorld>
        <Notice msg="Hello everyone!!!" />
        <a
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <Card title='hahaa' paragraph='jeng jeng jeng' />
        <Clock title='masa is' />
      </header> */}
      <HeroSection />
      <AboutSection />
      <ProjectSection />
      <WorkSection />
    </div>
  );
}

export default App;
