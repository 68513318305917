import myPhoto from './me.jpg';

const AboutSection = () => {
   return (
      <div className="py-28 px-8 sm:py-40 sm:px-12 bg-tertiary">
         <div className="container flex justify-around">
            <div>
               <img src={myPhoto} alt="Thim" className="rounded-full w-32 sm:w-80" />
            </div>
            <div className="flex flex-col justify-center w-1/2">
               <p className="text-2xl sm:text-6xl font-primary font-bold">Thim</p>
               <p className="text-lg sm:text-2xl font-secondary italic font-light mt-2">a software engineer</p>
               <p className="font-body mt-4">I have been working in tech industry as a full stack engineer for past 7 years. Working in several start-ups have made me opened up to plenty of new technologies and coding languages, which drive me into making great products. Whether you're planning to make a website, a web application, a mobile app, or any tech solution, hit me up!</p>
            </div>
         </div>
      </div>
   );
}

export default AboutSection;
